import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHDate, HHGrid, HHTypography, PriorityStates } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { get, lowerCase } from 'lodash';
import { useNavigate } from 'react-router';
import { NAV_SPLIT_TREATMENTS, ON } from '../../constants/strings/split';
import { useCurrentStoredAdminHook } from '../../hooks/use-current-stored-admin';
import { PT_MEMBER_VIEW_HEADER_TITLE } from '../../modules/acute-physical-therapy/constants/strings/acute';
import { getVideoVisitsTypeByFollowups } from '../../modules/acute-physical-therapy/utils/video-visits';
import { COACH_WORKFLOW_TYPES } from '../../modules/dashboard/constants/strings/coach-workflow-types';
import { SIMPLIFIED_WORKFLOWS } from '../../modules/member-360/constants/splits';
import { MEMBER_ALERT_THEME } from '../../modules/member-360/modules/member-alerts/constants/theme';
import { buildWorkflowUrl } from '../utils';
import { useUpcomingEncounter } from './hooks/use-upcoming-encounter';
const styles = {
  upcomingEncounterBox: {
    backgroundColor: ctpTokens.palette.neutral['99'],
    mb: 6,
    mx: 8,
    py: 2,
    px: 4,
    cursor: 'pointer',
    borderRadius: 1
  },
  title: {
    mb: 2
  },
  subtitle: {
    mt: 1,
    color: MEMBER_ALERT_THEME.COLOR.TEXT.SECONDARY
  },
  upcomingEncounterDateGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
};
export const UpcomingEncounterBox = () => {
  const $ = _c(30);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const upcomingEncounter = useUpcomingEncounter();
  const splitClient = useClient();
  const navigate = useNavigate();
  if (!upcomingEncounter) {
    let t0;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <></>;
      $[0] = t0;
    } else {
      t0 = $[0];
    }
    return t0;
  }
  const {
    callDate,
    patientDetails,
    followups,
    applicationPathway,
    type,
    workflowId,
    patientId
  } = upcomingEncounter;
  const isSimplifiedWorkflowsSplitOn = splitClient?.getTreatment(SIMPLIFIED_WORKFLOWS, {
    adminUuid: currentStoredAdmin?.uuid || "*"
  }) === ON;
  const videoVisitDeprecationTreatment = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.CTT_DEPRECATION_VIDEO_VISIT, {
    adminUuid: currentStoredAdmin?.uuid || "*"
  }) === ON;
  let t0;
  if ($[1] !== workflowId || $[2] !== patientId || $[3] !== type || $[4] !== isSimplifiedWorkflowsSplitOn || $[5] !== videoVisitDeprecationTreatment || $[6] !== navigate) {
    t0 = () => {
      const encounterWorkflowUrl = buildWorkflowUrl({
        id: workflowId,
        patientId: patientId || "",
        type,
        nextId: 0
      }, isSimplifiedWorkflowsSplitOn, videoVisitDeprecationTreatment);
      if (encounterWorkflowUrl) {
        navigate(encounterWorkflowUrl);
      }
    };
    $[1] = workflowId;
    $[2] = patientId;
    $[3] = type;
    $[4] = isSimplifiedWorkflowsSplitOn;
    $[5] = videoVisitDeprecationTreatment;
    $[6] = navigate;
    $[7] = t0;
  } else {
    t0 = $[7];
  }
  const navigateToUpcomingEncounter = t0;
  const title = `Upcoming ${lowerCase(get(PT_MEMBER_VIEW_HEADER_TITLE, type, get(COACH_WORKFLOW_TYPES, type, "")))}`;
  let t1;
  if ($[8] !== followups || $[9] !== applicationPathway || $[10] !== type) {
    t1 = getVideoVisitsTypeByFollowups(followups, applicationPathway, type);
    $[8] = followups;
    $[9] = applicationPathway;
    $[10] = type;
    $[11] = t1;
  } else {
    t1 = $[11];
  }
  const subtitle = t1;
  let t2;
  if ($[12] !== title) {
    t2 = <HHBox sx={styles.title}><HHTypography hhVariant="h3">{title}</HHTypography></HHBox>;
    $[12] = title;
    $[13] = t2;
  } else {
    t2 = $[13];
  }
  let t3;
  if ($[14] !== patientDetails) {
    t3 = <HHBox><HHTypography hhVariant="body1">{patientDetails}</HHTypography></HHBox>;
    $[14] = patientDetails;
    $[15] = t3;
  } else {
    t3 = $[15];
  }
  let t4;
  if ($[16] !== subtitle) {
    t4 = <HHBox sx={styles.subtitle}><HHTypography hhVariant="body1">{subtitle}</HHTypography></HHBox>;
    $[16] = subtitle;
    $[17] = t4;
  } else {
    t4 = $[17];
  }
  let t5;
  if ($[18] !== t2 || $[19] !== t3 || $[20] !== t4) {
    t5 = <HHGrid item={true} xs={9}>{t2}{t3}{t4}</HHGrid>;
    $[18] = t2;
    $[19] = t3;
    $[20] = t4;
    $[21] = t5;
  } else {
    t5 = $[21];
  }
  let t6;
  if ($[22] !== callDate) {
    t6 = <HHGrid item={true} xs={3} sx={styles.upcomingEncounterDateGrid}><HHDate dueDate={callDate} iconPosition="left" dueState={PriorityStates.Default} iconColor={ctpTokens.palette.navy["40"]} showScheduledTime={true} /></HHGrid>;
    $[22] = callDate;
    $[23] = t6;
  } else {
    t6 = $[23];
  }
  let t7;
  if ($[24] !== t5 || $[25] !== t6) {
    t7 = <HHGrid container={true}>{t5}{t6}</HHGrid>;
    $[24] = t5;
    $[25] = t6;
    $[26] = t7;
  } else {
    t7 = $[26];
  }
  let t8;
  if ($[27] !== navigateToUpcomingEncounter || $[28] !== t7) {
    t8 = <HHBox sx={styles.upcomingEncounterBox} onClick={navigateToUpcomingEncounter}>{t7}</HHBox>;
    $[27] = navigateToUpcomingEncounter;
    $[28] = t7;
    $[29] = t8;
  } else {
    t8 = $[29];
  }
  return t8;
};