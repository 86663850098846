export const ON = 'on';
export const OFF = 'off';
export const JIRA_FEEDBACK_TREATMENT = 'jira';
export const FRESHDESK_FEEDBACK_TREATMENT = 'freshdesk';
export const NAV_SPLIT_TREATMENTS = {
  WORKFLOWS_QUEUE: 'workflows-queue',
  COACH_CALLS: 'coach-calls',
  REENGAGER_WORKFLOW_MIGRATION: 'reengager-workflow-migration',
  CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT: 'care-coordination-workflow-panel',
  ENCOUNTERS_TAB: 'encounters-tab',
  PHI_MASKING_SWITCH_BUTTON: 'runtime-phi-masking-switch-button',
  CTT_DEPRECATION_VIDEO_VISIT: 'ctt-deprecation-video-visit',
  SETUP_ACTION_PLAN_WORKFLOW: 'setup-action-plan-workflow',
  MIXPANEL_CLICK_TRACKING: 'mixpanel-click-tracking',
  OS_ONBOARDING: 'os-onboarding',
  USER_FEEDBACK: 'user-feedback',
  COACH_VIDEO_VISIT: 'coach-video-visit',
  MPE_QUEUE: 'mpe-queue',
  FEEDBACK_VENDOR: 'carehub-feedback-vendor',
  REDIRECT_CTT_TO_CTP: 'redirect-ctt-to-ctp'
};