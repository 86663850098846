import { c as _c } from "react-compiler-runtime";
import { useClient } from '@splitsoftware/splitio-react';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { ON, SPLIT_TREATMENTS } from '../../../../constants/splits';
import { PANEL_CATEGORY } from '../../../../constants/strings/coach-layout';
import { OS_PANEL_CATEGORY } from '../../../../constants/strings/os-layout';
import { PT_PANEL_CATEGORY } from '../../../../constants/strings/pt-layout';
import { useShellContext } from '../../../../contexts/shell.context';
import { DashboardType } from '../../../../custom-types';
import { CoachDashboardFilters } from '../../../../types/coach';
import { OsDashboardFilters } from '../../../../types/os';
import { PtDashboardFilters } from '../../../../types/pt';
interface FilterCategory<T> {
  name: Array<T>;
  category?: string;
  id: string;
  showDivider?: boolean;
  showCategoryHeader?: boolean;
}
export const useGetFilterCategories = dashboardType => {
  const $ = _c(57);
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const healthLogNotesTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const reEngagerTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.REENGAGER_WORKFLOW_MIGRATION, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const setupActionPlanTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const isPtActionPlanReviewWorkflowSplitOn = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachWeeklyGoalExpiry = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachMemberUpdatedWeeklyGoal = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachVideoVisitSplitOn = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_VIDEO_VISIT, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  let t0;
  bb0: {
    let tempAccordionList;
    if ($[0] !== dashboardType || $[1] !== coachVideoVisitSplitOn || $[2] !== healthLogNotesTreatment || $[3] !== reEngagerTreatment || $[4] !== coachWeeklyGoalExpiry || $[5] !== coachMemberUpdatedWeeklyGoal || $[6] !== setupActionPlanTreatment || $[7] !== isPtActionPlanReviewWorkflowSplitOn) {
      tempAccordionList = [];
      if (dashboardType === DashboardType.Coach) {
        if (coachVideoVisitSplitOn) {
          let t1;
          if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
            t1 = {
              name: [CoachDashboardFilters.CoachVideoVisit],
              id: "0"
            };
            $[10] = t1;
          } else {
            t1 = $[10];
          }
          tempAccordionList.push(t1);
        }
        let t1;
        if ($[11] === Symbol.for("react.memo_cache_sentinel")) {
          t1 = {
            name: [CoachDashboardFilters.CoachCalls],
            id: "1",
            showDivider: true
          };
          $[11] = t1;
        } else {
          t1 = $[11];
        }
        tempAccordionList.push(t1);
        let t2;
        if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
          t2 = {
            name: [CoachDashboardFilters.All],
            category: "",
            showDivider: true,
            id: "2"
          };
          $[12] = t2;
        } else {
          t2 = $[12];
        }
        tempAccordionList.push(t2);
        let t3;
        let unreadWorkflows;
        if ($[13] !== healthLogNotesTreatment) {
          unreadWorkflows = [CoachDashboardFilters.UnreadMessages];
          if (healthLogNotesTreatment) {
            unreadWorkflows.push(CoachDashboardFilters.MemberHealthLog);
          }
          t3 = isEmpty(unreadWorkflows);
          $[13] = healthLogNotesTreatment;
          $[14] = t3;
          $[15] = unreadWorkflows;
        } else {
          t3 = $[14];
          unreadWorkflows = $[15];
        }
        if (!t3) {
          let t4;
          if ($[16] !== unreadWorkflows) {
            t4 = {
              name: unreadWorkflows,
              id: "3",
              category: PANEL_CATEGORY.UNREADS,
              showCategoryHeader: true,
              showDivider: true
            };
            $[16] = unreadWorkflows;
            $[17] = t4;
          } else {
            t4 = $[17];
          }
          tempAccordionList.push(t4);
        }
        if (reEngagerTreatment) {
          let t4;
          if ($[18] === Symbol.for("react.memo_cache_sentinel")) {
            t4 = {
              name: [CoachDashboardFilters.ReEngager],
              id: "4"
            };
            $[18] = t4;
          } else {
            t4 = $[18];
          }
          tempAccordionList.push(t4);
        }
        let t4;
        if ($[19] === Symbol.for("react.memo_cache_sentinel")) {
          t4 = {
            name: [CoachDashboardFilters.CoachEscalation],
            id: "5"
          };
          $[19] = t4;
        } else {
          t4 = $[19];
        }
        tempAccordionList.push(t4);
        let t5;
        if ($[20] === Symbol.for("react.memo_cache_sentinel")) {
          t5 = [CoachDashboardFilters.FirstWeekCheckIn, CoachDashboardFilters.FirstWeekReview];
          $[20] = t5;
        } else {
          t5 = $[20];
        }
        let t6;
        if ($[21] === Symbol.for("react.memo_cache_sentinel")) {
          t6 = {
            name: t5,
            category: PANEL_CATEGORY.FIRST_WEEK_GOAL,
            id: "6",
            showCategoryHeader: true
          };
          $[21] = t6;
        } else {
          t6 = $[21];
        }
        tempAccordionList.push(t6);
        let actionPlanWorkflows;
        if ($[22] !== coachWeeklyGoalExpiry || $[23] !== coachMemberUpdatedWeeklyGoal) {
          actionPlanWorkflows = [];
          actionPlanWorkflows.push(CoachDashboardFilters.ActionPlanCheckIn, CoachDashboardFilters.ReviewAndRecalibrate);
          if (coachWeeklyGoalExpiry) {
            actionPlanWorkflows.push(CoachDashboardFilters.CoachWeeklyGoalExpiry);
          }
          if (coachMemberUpdatedWeeklyGoal) {
            actionPlanWorkflows.push(CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal);
          }
          $[22] = coachWeeklyGoalExpiry;
          $[23] = coachMemberUpdatedWeeklyGoal;
          $[24] = actionPlanWorkflows;
        } else {
          actionPlanWorkflows = $[24];
        }
        let t7;
        if ($[25] !== actionPlanWorkflows) {
          t7 = {
            name: actionPlanWorkflows,
            category: PANEL_CATEGORY.ACTION_PLAN,
            id: "7",
            showCategoryHeader: true
          };
          $[25] = actionPlanWorkflows;
          $[26] = t7;
        } else {
          t7 = $[26];
        }
        tempAccordionList.push(t7);
        let t8;
        if ($[27] === Symbol.for("react.memo_cache_sentinel")) {
          t8 = [CoachDashboardFilters.WeeklyEnsoReview];
          $[27] = t8;
        } else {
          t8 = $[27];
        }
        const ensoWorkflows = t8;
        let t9;
        if ($[28] === Symbol.for("react.memo_cache_sentinel")) {
          t9 = {
            name: ensoWorkflows,
            category: PANEL_CATEGORY.ENSO,
            id: "8",
            showCategoryHeader: true
          };
          $[28] = t9;
        } else {
          t9 = $[28];
        }
        tempAccordionList.push(t9);
        let t10;
        if ($[29] === Symbol.for("react.memo_cache_sentinel")) {
          t10 = [CoachDashboardFilters.MaternityMilestones, CoachDashboardFilters.PregnancyStatusChange];
          $[29] = t10;
        } else {
          t10 = $[29];
        }
        let t11;
        if ($[30] === Symbol.for("react.memo_cache_sentinel")) {
          t11 = {
            name: t10,
            category: PANEL_CATEGORY.PELVIC_HEALTH,
            id: "9",
            showCategoryHeader: true
          };
          $[30] = t11;
        } else {
          t11 = $[30];
        }
        tempAccordionList.push(t11);
        let t12;
        if ($[31] === Symbol.for("react.memo_cache_sentinel")) {
          t12 = [CoachDashboardFilters.AlertReminder, CoachDashboardFilters.BalanceAssessment, CoachDashboardFilters.OutreachNeeded];
          $[31] = t12;
        } else {
          t12 = $[31];
        }
        const otherWorkflows = t12;
        let t13;
        if ($[32] === Symbol.for("react.memo_cache_sentinel")) {
          t13 = {
            name: otherWorkflows,
            category: PANEL_CATEGORY.OTHER_WORKFLOWS,
            id: "10",
            showDivider: true,
            showCategoryHeader: true
          };
          $[32] = t13;
        } else {
          t13 = $[32];
        }
        tempAccordionList.push(t13);
        let t14;
        if ($[33] === Symbol.for("react.memo_cache_sentinel")) {
          t14 = {
            name: [CoachDashboardFilters.Completed],
            id: "11"
          };
          $[33] = t14;
        } else {
          t14 = $[33];
        }
        tempAccordionList.push(t14);
      } else {
        if (dashboardType === DashboardType.PhysicalTherapist) {
          let t1;
          if ($[34] === Symbol.for("react.memo_cache_sentinel")) {
            t1 = {
              name: [PtDashboardFilters.VideoVisit],
              id: "0"
            };
            $[34] = t1;
          } else {
            t1 = $[34];
          }
          let t2;
          if ($[35] === Symbol.for("react.memo_cache_sentinel")) {
            t2 = {
              name: [PtDashboardFilters.HouseCall],
              id: "1",
              showDivider: true
            };
            $[35] = t2;
          } else {
            t2 = $[35];
          }
          let t3;
          if ($[36] === Symbol.for("react.memo_cache_sentinel")) {
            t3 = {
              name: [PtDashboardFilters.All],
              id: "2",
              showDivider: true
            };
            $[36] = t3;
          } else {
            t3 = $[36];
          }
          let tempAccordionList_0;
          if ($[37] !== setupActionPlanTreatment || $[38] !== isPtActionPlanReviewWorkflowSplitOn) {
            tempAccordionList_0 = [t1, t2, t3];
            let t4;
            if ($[40] === Symbol.for("react.memo_cache_sentinel")) {
              t4 = {
                name: [PtDashboardFilters.UnreadMessage],
                id: "3"
              };
              $[40] = t4;
            } else {
              t4 = $[40];
            }
            tempAccordionList_0.push(t4);
            let t5;
            let clinicalWorkflows;
            if ($[41] === Symbol.for("react.memo_cache_sentinel")) {
              clinicalWorkflows = [PtDashboardFilters.MedicalEmergencyFollowUp, PtDashboardFilters.ClinicalEscalation, PtDashboardFilters.EtModification, PtDashboardFilters.SurgeryTrend, PtDashboardFilters.IndicationUpdate, PtDashboardFilters.AqRedFlags];
              t5 = isEmpty(clinicalWorkflows);
              $[41] = t5;
              $[42] = clinicalWorkflows;
            } else {
              t5 = $[41];
              clinicalWorkflows = $[42];
            }
            if (!t5) {
              let t6;
              if ($[43] !== clinicalWorkflows) {
                t6 = {
                  name: clinicalWorkflows,
                  category: PT_PANEL_CATEGORY.CLINICAL_WORKFLOWS,
                  id: "4",
                  showCategoryHeader: true
                };
                $[43] = clinicalWorkflows;
                $[44] = t6;
              } else {
                t6 = $[44];
              }
              tempAccordionList_0.push(t6);
            }
            let t6;
            if ($[45] === Symbol.for("react.memo_cache_sentinel")) {
              t6 = [PtDashboardFilters.HealthLogNotes, PtDashboardFilters.PainUptick];
              $[45] = t6;
            } else {
              t6 = $[45];
            }
            let t7;
            if ($[46] === Symbol.for("react.memo_cache_sentinel")) {
              t7 = {
                name: t6,
                id: "5",
                category: PT_PANEL_CATEGORY.HEALTH_LOGS,
                showCategoryHeader: true
              };
              $[46] = t7;
            } else {
              t7 = $[46];
            }
            tempAccordionList_0.push(t7);
            const actionPlanWorkflows_0 = [];
            if (setupActionPlanTreatment) {
              actionPlanWorkflows_0.push(PtDashboardFilters.SetupActionPlan);
            }
            if (isPtActionPlanReviewWorkflowSplitOn) {
              actionPlanWorkflows_0.push(PtDashboardFilters.ActionPlanReview);
            }
            if (!isEmpty(actionPlanWorkflows_0)) {
              let t8;
              if ($[47] !== actionPlanWorkflows_0) {
                t8 = {
                  name: actionPlanWorkflows_0,
                  category: PT_PANEL_CATEGORY.ACTION_PLANS,
                  id: "6",
                  showCategoryHeader: true
                };
                $[47] = actionPlanWorkflows_0;
                $[48] = t8;
              } else {
                t8 = $[48];
              }
              tempAccordionList_0.push(t8);
            }
            let t8;
            if ($[49] === Symbol.for("react.memo_cache_sentinel")) {
              t8 = [PtDashboardFilters.FirstWeekEnsoCheckIn, PtDashboardFilters.PelvicTrainerOutreach];
              $[49] = t8;
            } else {
              t8 = $[49];
            }
            let t9;
            if ($[50] === Symbol.for("react.memo_cache_sentinel")) {
              t9 = {
                name: t8,
                category: PT_PANEL_CATEGORY.DEVICES,
                id: "7",
                showCategoryHeader: true
              };
              $[50] = t9;
            } else {
              t9 = $[50];
            }
            tempAccordionList_0.push(t9);
            let t10;
            if ($[51] === Symbol.for("react.memo_cache_sentinel")) {
              t10 = [PtDashboardFilters.ExpiringCarePlans, PtDashboardFilters.DischargeAndClosePlan];
              $[51] = t10;
            } else {
              t10 = $[51];
            }
            let t11;
            if ($[52] === Symbol.for("react.memo_cache_sentinel")) {
              t11 = {
                name: t10,
                category: PT_PANEL_CATEGORY.CARE_PLANS,
                id: "8",
                showCategoryHeader: true
              };
              $[52] = t11;
            } else {
              t11 = $[52];
            }
            tempAccordionList_0.push(t11);
            let t12;
            if ($[53] === Symbol.for("react.memo_cache_sentinel")) {
              t12 = [PtDashboardFilters.PtMemberAlertReminder];
              $[53] = t12;
            } else {
              t12 = $[53];
            }
            let t13;
            if ($[54] === Symbol.for("react.memo_cache_sentinel")) {
              t13 = {
                name: t12,
                category: PT_PANEL_CATEGORY.OTHERS,
                id: "9",
                showCategoryHeader: true,
                showDivider: true
              };
              $[54] = t13;
            } else {
              t13 = $[54];
            }
            tempAccordionList_0.push(t13);
            let t14;
            if ($[55] === Symbol.for("react.memo_cache_sentinel")) {
              t14 = {
                name: [PtDashboardFilters.Completed],
                id: "10"
              };
              $[55] = t14;
            } else {
              t14 = $[55];
            }
            tempAccordionList_0.push(t14);
            $[37] = setupActionPlanTreatment;
            $[38] = isPtActionPlanReviewWorkflowSplitOn;
            $[39] = tempAccordionList_0;
          } else {
            tempAccordionList_0 = $[39];
          }
          t0 = tempAccordionList_0;
          break bb0;
        } else {
          if (dashboardType === DashboardType.OnboardingSpecialist) {
            let tempAccordionList_1;
            if ($[56] === Symbol.for("react.memo_cache_sentinel")) {
              tempAccordionList_1 = [{
                name: [OsDashboardFilters.All],
                id: "0",
                showDivider: true
              }];
              tempAccordionList_1.push({
                name: [OsDashboardFilters.UnreadMessage],
                id: "1",
                category: OS_PANEL_CATEGORY.UNREADS,
                showCategoryHeader: true,
                showDivider: true
              });
              tempAccordionList_1.push({
                name: [OsDashboardFilters.ReEngager],
                id: "2"
              });
              tempAccordionList_1.push({
                name: [OsDashboardFilters.Nuto, OsDashboardFilters.Ruto],
                id: "3",
                category: OS_PANEL_CATEGORY.NUTO_OR_RUTO,
                showCategoryHeader: true
              });
              const otherWorkflows_0 = [OsDashboardFilters.AlertReminder, OsDashboardFilters.OutreachNeeded];
              tempAccordionList_1.push({
                name: otherWorkflows_0,
                category: PANEL_CATEGORY.OTHER_WORKFLOWS,
                id: "4",
                showDivider: true,
                showCategoryHeader: true
              });
              tempAccordionList_1.push({
                name: [OsDashboardFilters.Completed],
                id: "4"
              });
              $[56] = tempAccordionList_1;
            } else {
              tempAccordionList_1 = $[56];
            }
            t0 = tempAccordionList_1;
            break bb0;
          }
        }
      }
      $[0] = dashboardType;
      $[1] = coachVideoVisitSplitOn;
      $[2] = healthLogNotesTreatment;
      $[3] = reEngagerTreatment;
      $[4] = coachWeeklyGoalExpiry;
      $[5] = coachMemberUpdatedWeeklyGoal;
      $[6] = setupActionPlanTreatment;
      $[7] = isPtActionPlanReviewWorkflowSplitOn;
      $[8] = tempAccordionList;
      $[9] = t0;
    } else {
      tempAccordionList = $[8];
      t0 = $[9];
    }
    t0 = tempAccordionList;
  }
  const accordionList = t0;
  return (accordionList as Array<FilterCategory<T>>);
};