import { AccessToken } from '@okta/okta-auth-js';
import { defaultErrorHandler } from './errors';
interface EventCallback {
  callbacks: Record<string, (event: Event) => void>;
  last?: MessageEvent;
}
export class SseClient {
  private eventSource?: EventSource;
  private eventCallbacks: Map<string, EventCallback>;
  public initialized = false;
  constructor() {
    this.eventCallbacks = new Map();
    this.eventSource = undefined;
  }
  private isTokenExpired({
    expireAt,
    refreshThreshold = 180
  } // refresh the token 3 minutes before it expires
  : {
    expireAt: number | undefined;
    refreshThreshold?: number;
  }): boolean {
    if (!expireAt) return true;
    const currentTime = Math.floor(Date.now() / 1000);
    return expireAt - currentTime < refreshThreshold; // Compare expiration time with current time
  }
  private setTokenCookieForDomain(url: string, token: string): void {
    try {
      const urlString = new URL(url);
      const hostname = urlString.hostname;
      if (hostname === 'localhost') {
        document.cookie = `token=${token}; Secure`;
      } else {
        const domain = `.${hostname.split('.').slice(1).join('.')}`;
        document.cookie = `token=${token}; domain=${domain}; Secure`;
      }
    } catch (error) {
      console.warn('Error setting domain in cookie:', error);
      document.cookie = `token=${token}; Secure`;
    }
  }
  private appendTokenToURL(url: string, token: string): string {
    try {
      const urlString = new URL(url);
      urlString.searchParams.set('token', token); // add or update the token query parameter
      return urlString.toString();
    } catch (error) {
      console.warn('Error parsing URL', error);
    }
    return url;
  }
  public async init({
    url,
    token,
    accessToken,
    getOrRenewAccessToken
  }: {
    url: string;
    token?: string;
    accessToken?: AccessToken;
    getOrRenewAccessToken: () => Promise<string | null>;
  }): Promise<void> {
    if (!token || this.initialized) return; // Skip initialization if already initialized or no token
    // Check if the token is about to expire.
    // If yes, refresh the token and and return
    // without subscribing to SSE events.
    if (this.isTokenExpired({
      expireAt: accessToken?.expiresAt
    })) {
      try {
        await getOrRenewAccessToken();
        return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        defaultErrorHandler(`Okta error: ${error.message}`);
      }
    }
    if (process.env.DISABLE_SSE_COOKIES === 'true') {
      url = this.appendTokenToURL(url, token);
    } else {
      this.setTokenCookieForDomain(url, token);
    }

    // Add a keep-alive event to maintain the connection
    const setIntervalId: NodeJS.Timer = setInterval(() => {
      this.sendKeepAliveEvent();
    }, 20000); // Every 20 seconds

    this.eventSource = new EventSource(url, {
      withCredentials: true
    });
    this.initialized = true;
    this.eventSource.onerror = async (error): Promise<void> => {
      console.warn('SSE connection error:', error);
      clearInterval(setIntervalId);
      // Close the existing connection before attempting reconnection
      this.close();
      // Attempt reconnection
      await this.init({
        url,
        token,
        accessToken,
        getOrRenewAccessToken
      });
    };
    this.eventSource.onmessage = (event): void => {
      const parsedEvent = JSON.parse(event.data);
      const eventType = parsedEvent?.type;
      const eventHandler = this.eventCallbacks.get(eventType);
      const eventHandlers = Object.values(eventHandler?.callbacks || {});
      if (eventHandler && eventHandlers.length) {
        eventHandlers.forEach(callback => {
          callback(parsedEvent);
        });
      } else {
        if (eventType !== 'keepAlive') {
          console.warn('No handler for incoming event', parsedEvent?.type);
        }
      }
      this.eventCallbacks.set(eventType, {
        callbacks: eventHandler?.callbacks || {},
        last: parsedEvent
      });
    };
  }
  private sendKeepAliveEvent(): void {
    try {
      this.eventSource?.dispatchEvent(new Event('keepAlive'));
    } catch (e) {
      console.warn('Error Dispatching keep-alive event from client', e);
    }
  }
  public replay(eventType: string): void {
    const eventHandler = this.eventCallbacks.get(eventType);
    const callbacks = Object.values(eventHandler?.callbacks || {});
    if (!callbacks.length || !eventHandler?.last) return;
    callbacks.forEach(callback => {
      if (eventHandler.last) {
        callback(eventHandler.last);
      }
    });
  }
  public subscribe(message: string, subscriberId: string, callback: (event: Event) => unknown): void {
    const eventHandlers = this.eventCallbacks.get(message);
    const callbacks = eventHandlers?.callbacks || {};
    this.eventCallbacks.set(message, {
      callbacks: {
        ...callbacks,
        [subscriberId]: callback
      },
      last: eventHandlers?.last
    });
  }
  public unsubscribe(message: string, subscriberId: string): void {
    const eventHandler = this.eventCallbacks.get(message);
    if (!eventHandler) return;
    const {
      [subscriberId]: removedSubscriberId,
      ...otherCallbacks
    } = eventHandler.callbacks;
    this.eventCallbacks.set(message, {
      callbacks: otherCallbacks,
      last: eventHandler.last
    });
  }
  public close(): void {
    this.eventSource?.close();
    this.initialized = false;
  }
}