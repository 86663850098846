import { c as _c } from "react-compiler-runtime";
import { defaultCtpTheme, HHBox, HHTableBody, HHTypography } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { generateKey } from '../../../../../utils/app-utils';
import { SPLIT_TREATMENTS as PT_SPLIT_TREATEMENTS } from '../../../../acute-physical-therapy/constants/splits';
import { ON } from '../../../constants/splits';
import { LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { MISSING_CUSTOM_FIELDS_SENTRY_ERROR } from '../../../constants/strings/coach-layout';
import { OS_NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/os-layout';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { COACH_WORKFLOWS_TABLE, PT_WORKFLOWS_TABLE } from '../../../constants/strings/workflows-table';
import { useShellContext } from '../../../contexts/shell.context';
import { DashboardType, UnreadFilterType } from '../../../custom-types';
import { changePT, listToMarkWorkflowComplete, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters, selectedUnreadType, totalAllTypesCountLowPriority, totalModularIamCountLowPriority, totalUnreadCountLowPriority, totalWorkflowCount } from '../../../jotai/atoms';
import { WorkflowPayload } from '../../../types';
import { CoachWorkflowTypes } from '../../../types/coach';
import { ptCarePlanFilters, PtDashboardFilters } from '../../../types/pt';
import { getCompleteWorkflowFilters } from '../../../utils/get-complete-workflow-filters';
import { defaultErrorHandler } from '../../../utils/sentry-error-handler';
import { WorkflowsSelectionHeader } from './workflows-selection-header';
import { WorkflowsTableHeader } from './workflows-table-header';
import { WorkflowsTableRow } from './workflows-table-row';
const styles = {
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 200,
    right: 0,
    bottom: 0,
    left: 296 // 56 navbar + 240 filters
  }
};
export interface TableHeader {
  label: string;
  value?: string;
  enableSort?: boolean;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  enableFilter?: boolean;
}
export const tableHeaders: TableHeader[] = [{
  label: COACH_WORKFLOWS_TABLE.HEADERS.SELECT,
  enableSort: false
}, {
  label: PT_WORKFLOWS_TABLE.HEADERS.CHANNEL
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.TYPE,
  enableFilter: true
}, {
  label: PT_WORKFLOWS_TABLE.HEADERS.MESSAGE
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.MEMBER
}, {
  label: PT_WORKFLOWS_TABLE.HEADERS.STATE
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.CREATED_ON
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.COMPLETED_ON
}, {
  label: PT_WORKFLOWS_TABLE.HEADERS.PLAN_EXPIRATION
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.ENCOUNTER_DATE
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.DUE,
  value: 'latestTaskDate',
  enableSort: true,
  align: 'left'
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.MARK
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.COUNT_SELECTED
}, {
  label: COACH_WORKFLOWS_TABLE.HEADERS.MARK_COMPLETE
}];
export const TableHeadersWidth = () => {
  const $ = _c(5);
  const [selectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const [isPTChanged] = useAtom(changePT);
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const ptPooledReassignementTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.PT_POOLED_REASSIGNEMNT, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const isCompleteWorkflowFilter = getCompleteWorkflowFilters([selectedDashboardFilter.filterLabel, selectedPtDashboardFilter.filterLabel, selectedOsDashboardFilter.filterLabel]);
  const carePlanWorkflow = ptCarePlanFilters.includes(selectedPtDashboardFilter.filterLabel);
  if (isCompleteWorkflowFilter) {
    let t0;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <colgroup><col style={{
          width: "35%"
        }} /><col style={{
          width: "35%"
        }} /><col style={{
          width: "30%"
        }} /></colgroup>;
      $[0] = t0;
    } else {
      t0 = $[0];
    }
    return t0;
  }
  if (carePlanWorkflow) {
    let t0;
    if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <colgroup><col style={{
          width: "24%"
        }} /><col style={{
          width: "19%"
        }} /><col style={{
          width: "19%"
        }} /><col style={{
          width: "19%"
        }} /></colgroup>;
      $[1] = t0;
    } else {
      t0 = $[1];
    }
    return t0;
  }
  if (selectedDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME || selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME || selectedOsDashboardFilter.filterTabName === OS_NO_RESPONSE_PREDICTED_TAB_NAME || currentDashboard === DashboardType.PhysicalTherapist && isPTChanged && ptPooledReassignementTreatment) {
    let t0;
    if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <colgroup><col style={{
          width: "1%"
        }} /><col style={{
          width: "27%"
        }} /><col style={{
          width: "27%"
        }} /><col style={{
          width: "27%"
        }} /><col style={{
          width: "30%"
        }} /><col style={{
          width: "5%"
        }} /></colgroup>;
      $[2] = t0;
    } else {
      t0 = $[2];
    }
    return t0;
  }
  if (currentDashboard === DashboardType.PhysicalTherapist) {
    let t0;
    if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <colgroup><col style={{
          width: "24%"
        }} /><col style={{
          width: "19%"
        }} /><col style={{
          width: "19%"
        }} /><col style={{
          width: "19%"
        }} /><col style={{
          width: "19%"
        }} /></colgroup>;
      $[3] = t0;
    } else {
      t0 = $[3];
    }
    return t0;
  }
  let t0;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <colgroup><col style={{
        width: "30%"
      }} /><col style={{
        width: "30%"
      }} /><col style={{
        width: "20%"
      }} /><col style={{
        width: "20%"
      }} /></colgroup>;
    $[4] = t0;
  } else {
    t0 = $[4];
  }
  return t0;
};
export const WorkflowsTableContainer = t0 => {
  const $ = _c(27);
  const {
    workflows
  } = t0;
  const [selectedCoachDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const [listToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [workflowCount] = useAtom(totalWorkflowCount);
  const [allWorkflowFlag] = useAtom(selectAllWorkflowAtom);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const [allPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [totalUnreadLowPriority] = useAtom(totalUnreadCountLowPriority);
  const [totalAllTypesLowPriority] = useAtom(totalAllTypesCountLowPriority);
  const [totalmodularIAMLowPriority] = useAtom(totalModularIamCountLowPriority);
  const [selectedUnreadFilter] = useAtom(selectedUnreadType);
  const isCoachDashboard = currentDashboard === DashboardType.Coach;
  const isOsDashboard = currentDashboard === DashboardType.OnboardingSpecialist;
  let containerHeader;
  let t1;
  bb0: {
    switch (currentDashboard) {
      case DashboardType.PhysicalTherapist:
        {
          t1 = selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME;
          break bb0;
        }
      case DashboardType.Coach:
        {
          t1 = selectedCoachDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME;
          break bb0;
        }
      case DashboardType.OnboardingSpecialist:
        {
          t1 = selectedOsDashboardFilter.filterTabName === OS_NO_RESPONSE_PREDICTED_TAB_NAME;
          break bb0;
        }
    }
    t1 = undefined;
  }
  const showContainerHeader = t1;
  let t2;
  if ($[0] !== selectedPtDashboardFilter.filterLabel || $[1] !== selectedPtDashboardFilter.filterTabName || $[2] !== isCoachDashboard || $[3] !== isOsDashboard || $[4] !== workflowCount || $[5] !== selectedUnreadFilter || $[6] !== totalAllTypesLowPriority || $[7] !== totalmodularIAMLowPriority || $[8] !== totalUnreadLowPriority) {
    t2 = () => {
      const isNotUnreadMessageFilter = selectedPtDashboardFilter.filterLabel !== PtDashboardFilters.UnreadMessage;
      const isNoResponsePredictedTab = selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME;
      if (isCoachDashboard || isOsDashboard || isNotUnreadMessageFilter) {
        return workflowCount;
      }
      if (isNoResponsePredictedTab) {
        switch (selectedUnreadFilter) {
          case UnreadFilterType.AllTypes:
            {
              return totalAllTypesLowPriority;
            }
          case UnreadFilterType.HealthLog:
            {
              return totalmodularIAMLowPriority;
            }
          default:
            {
              return totalUnreadLowPriority;
            }
        }
      }
      return workflowCount;
    };
    $[0] = selectedPtDashboardFilter.filterLabel;
    $[1] = selectedPtDashboardFilter.filterTabName;
    $[2] = isCoachDashboard;
    $[3] = isOsDashboard;
    $[4] = workflowCount;
    $[5] = selectedUnreadFilter;
    $[6] = totalAllTypesLowPriority;
    $[7] = totalmodularIAMLowPriority;
    $[8] = totalUnreadLowPriority;
    $[9] = t2;
  } else {
    t2 = $[9];
  }
  const getSelectionCount = t2;
  if (listToMarkComplete.length > 0 || allWorkflowFlag || allPTWorkflowFlag) {
    let t3;
    if ($[10] !== getSelectionCount) {
      t3 = getSelectionCount();
      $[10] = getSelectionCount;
      $[11] = t3;
    } else {
      t3 = $[11];
    }
    let t4;
    if ($[12] !== t3) {
      t4 = <WorkflowsSelectionHeader data-testid="workflow-selection-header" totalCount={t3} />;
      $[12] = t3;
      $[13] = t4;
    } else {
      t4 = $[13];
    }
    containerHeader = t4;
  } else {
    if (currentDashboard === DashboardType.PhysicalTherapist) {
      let t3;
      if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
        t3 = <WorkflowsTableHeader tableHeaders={tableHeaders} />;
        $[14] = t3;
      } else {
        t3 = $[14];
      }
      containerHeader = t3;
    } else {
      let t3;
      if ($[15] === Symbol.for("react.memo_cache_sentinel")) {
        t3 = <WorkflowsTableHeader tableHeaders={tableHeaders.filter(header => header.label !== COACH_WORKFLOWS_TABLE.HEADERS.ENCOUNTER_DATE)} />;
        $[15] = t3;
      } else {
        t3 = $[15];
      }
      containerHeader = t3;
    }
  }
  let t3;
  if ($[16] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <TableHeadersWidth />;
    $[16] = t3;
  } else {
    t3 = $[16];
  }
  let t4;
  if ($[17] !== showContainerHeader || $[18] !== containerHeader) {
    t4 = showContainerHeader ? containerHeader : <WorkflowsTableHeader tableHeaders={tableHeaders} />;
    $[17] = showContainerHeader;
    $[18] = containerHeader;
    $[19] = t4;
  } else {
    t4 = $[19];
  }
  let t5;
  if ($[20] !== workflows) {
    t5 = !!workflows?.length ? workflows.map((workflow, index) => {
      if (workflow.type === CoachWorkflowTypes.UnreadMessages && !workflow.customFields) {
        defaultErrorHandler(`${workflow.id} ${MISSING_CUSTOM_FIELDS_SENTRY_ERROR}`);
      }
      return <WorkflowsTableRow workflow={workflow} key={generateKey(`${workflow?.id}`, index)} />;
    }) : <HHBox sx={styles.emptyContainer}><HHTypography hhVariant="body1" color={defaultCtpTheme.palette.text.secondary}>No tasks</HHTypography></HHBox>;
    $[20] = workflows;
    $[21] = t5;
  } else {
    t5 = $[21];
  }
  let t6;
  if ($[22] !== t5) {
    t6 = <HHTableBody>{t5}</HHTableBody>;
    $[22] = t5;
    $[23] = t6;
  } else {
    t6 = $[23];
  }
  let t7;
  if ($[24] !== t4 || $[25] !== t6) {
    t7 = <>{t3}{t4}{t6}</>;
    $[24] = t4;
    $[25] = t6;
    $[26] = t7;
  } else {
    t7 = $[26];
  }
  return t7;
};