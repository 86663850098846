export const PT_LAYOUT = {
  LOADER_ID: 'pt-layout-loader',
  LAYOUT_CONTAINER_ID: 'pt-layout',
  FILTER_CONTAINER_ID: 'pt-dashboard-filter',
  WORKFLOWS_TABLE_CONTAINER_ID: 'pt-workflows-table'
};
export const PT_PANEL_CATEGORY = {
  HEALTH_LOGS: 'Health logs',
  CARE_PLANS: 'Care plans',
  CLINICAL_WORKFLOWS: 'Clinical workflows',
  ACTION_PLANS: 'Action Plans',
  OTHERS: 'Others',
  DEVICES: 'Devices',
  UNREADS: 'Unreads'
};
export const DEFAULT_PT_DASHBOARD_TAB_NAME = 'All Incoming';
export const NO_RESPONSE_PREDICTED_TAB_NAME = 'No Response Predicted';
export const PT_VIDEO_VISIT_TAB_NAMES = {
  INCOMPLETE_VIDEO_VISITS: 'Post Video Visit Tasks',
  UPCOMING_VIDEO_VISITS: 'Upcoming Video Visits'
};
export const HOUSE_CALL_TAB_NAMES = {
  INCOMPLETE_HOUSE_CALLS: 'Post House Call Tasks',
  UPCOMING_HOUSE_CALLS: 'Upcoming House Calls'
};
export const SURGERY_TREND_SECONDARY_INFO = {
  INCREASED_LIKELIHOOD: 'Increased likelihood',
  UPCOMING_PROCEDURE: 'Upcoming procedure'
};
export const PT_LIST_NO_RECENT_SEARCHES = 'No recent searches';
export const PT_LIST_NO_RESULTS = 'No results';