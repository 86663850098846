import { HHDropdownItemProps } from '@hinge-health/react-component-library';
import { MenuOptions } from '@hinge-health/react-component-library/dist/components/molecules/hh-layout-panel';
import { Channel, ChannelFilters, DefaultGenerics, StreamChat, TokenOrProvider, UserResponse } from 'stream-chat';
import { CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowCategories, PtWorkflowTypes, WorkflowTypes } from '../../../../../../custom-types';
import { CareTeamCoverageInfo, CoachCoverageType, WorkflowPayload } from '../../../../../../types';
import { OktaRole } from '../../../../../../types/okta-roles';
import { capitalizeFirstLetter } from '../../../../../../utils/string-helpers';
import { SPLIT_TREATMENTS } from '../../../../../dashboard/constants/splits';
import { Admin } from '../../../../../dashboard/types';
import { COACH_CREATE_WORKFLOW_OPTIONS, OS_CREATE_WORKFLOW_OPTIONS, PT_CREATE_WORKFLOW_OPTIONS } from '../../../../modules/panel-layouts/workflows-panel/constants/workflow-panels';
import { CONVERSATION_TYPE } from '../../conversations/constants/conversation';
import { PrimaryAssigneeDetails } from '../../iam/types/iam-types';
import { AcceptedRoles as Roles, AcceptedRolesForWorkflowList, ConversationRule } from '../types/types';
export const checkForExistingConversationsWithRole = async (adminRole: string | undefined, channels: Array<Channel> | undefined): Promise<boolean> => {
  const checkCondition = (metadata: Record<string, unknown>): boolean => metadata?.careTeamOwnerType === adminRole;
  return channels?.some(channel => checkCondition((channel?.data?.metadata as Record<string, unknown>))) || false;
};
export const conversationRules: Record<string, ConversationRule> = {
  coach: oneOnOneExists => oneOnOneExists,
  pt: (oneOnOneExists, groupExists, iamCareTeamMessagingEnabled) => iamCareTeamMessagingEnabled ? oneOnOneExists && groupExists : oneOnOneExists,
  default: () => false
};
interface ConnectStreamClientProps {
  client: StreamChat;
  user: UserResponse<DefaultGenerics>;
  token: TokenOrProvider;
}
export const connectStreamClient = async ({
  client,
  user,
  token
}: ConnectStreamClientProps): Promise<void> => {
  await client.connectUser(user, token);
};
export const disconnectStreamClient = async (client: StreamChat): Promise<void> => {
  await client.disconnectUser();
};
export const currentIamConversationFilter = (conversationId: string): ChannelFilters<DefaultGenerics> => ({
  type: CONVERSATION_TYPE,
  cid: {
    $in: [conversationId]
  }
});
export const currentIamConversationFilterByID = (channelId: string): ChannelFilters<DefaultGenerics> => ({
  type: CONVERSATION_TYPE,
  id: {
    $in: [channelId]
  }
});
export const currentIamMemberFilter = (userUuid: string | undefined): ChannelFilters<DefaultGenerics> => ({
  type: CONVERSATION_TYPE,
  members: {
    $in: [(userUuid as string)]
  }
});
export const iamFilterBuilder = (currentUserUuid: string | undefined): ChannelFilters<DefaultGenerics> => {
  const filter: ChannelFilters<DefaultGenerics> = currentIamMemberFilter(currentUserUuid);
  return filter;
};
export const splitEnabledInteractionsWorkflowTypesMap: { [key in WorkflowTypes]?: string } = {
  [CoachWorkflowTypes.ReEngager]: SPLIT_TREATMENTS.REENGAGER_WORKFLOW_MIGRATION,
  [PtWorkflowTypes.ActionPlanReview]: SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW,
  [CoachWorkflowTypes.CoachWeeklyGoalExpiry]: SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY,
  [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal]: SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL,
  [CoachWorkflowTypes.CoachVideoVisit]: SPLIT_TREATMENTS.COACH_VIDEO_VISIT
};
export const splitEnabledInteractionsWorkflowTypes: WorkflowTypes[] = (Object.keys(splitEnabledInteractionsWorkflowTypesMap) as WorkflowTypes[]);
export const allWorkflowCategories = Object.values({
  ...PtWorkflowCategories,
  ...CoachWorkflowTypes,
  PtActionPlanReview: PtWorkflowCategories.ActionPlans,
  PtUnreadMessages: PtWorkflowCategories.UnreadMessages
});
export const coachOrOsWorkflowTypes = Array.from(new Set([...Object.values(CoachWorkflowTypes), ...Object.values(OsWorkflowTypes)]));
export const allWorkflowTypes = Array.from(new Set([...Object.values(PtWorkflowTypes), ...coachOrOsWorkflowTypes]));
export const WORKFLOWS_TITLES = allWorkflowTypes.reduce(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(acc: any, val: string) => {
  const TITLE = capitalizeFirstLetter(val.replaceAll('-', ' '));
  return {
    ...acc,
    [val]: TITLE
  };
}, {});
export const WORKFLOWS_ROLES = {
  ...Object.fromEntries([...Object.values(CoachWorkflowTypes).map(key => [key, [AcceptedRolesForWorkflowList.Coach.toLowerCase()]]), ...Object.values(PtWorkflowTypes).map(key => [key, [AcceptedRolesForWorkflowList.PhysicalTherapist.toLowerCase(), AcceptedRolesForWorkflowList.Super.toLowerCase()]]), ...Object.values(OsWorkflowTypes).map(key => [key, [AcceptedRolesForWorkflowList.OnboardingSpecialist.toLowerCase()]])])
};
export const coveringAdmin = (workflow: WorkflowPayload): Admin | undefined => {
  const roles = WORKFLOWS_ROLES[workflow?.type];
  if (!roles) return undefined;
  const isPtRole = roles.includes('physical_therapist');
  //find PT with 'Clinical' coverage
  let coveringAdmin = workflow.coveringAdmins?.find(coveringAdmin => {
    const isAdminRole = roles.includes(coveringAdmin.role);
    const hasClinicalCoverage = coveringAdmin.coverageTypes.includes('Clinical');
    return isPtRole ? isAdminRole && hasClinicalCoverage : isAdminRole;
  });
  const isCoachRole = roles.includes('coach');
  const isOsRole = roles.includes('onboarding_specialist');
  //find an admin with 'Primary' coverage
  coveringAdmin = workflow.coveringAdmins?.find(coveringAdmin => {
    const isAdminRole = roles.includes(coveringAdmin.role);
    const hasPrimaryCoverage = coveringAdmin.coverageTypes.includes('Primary');
    return isCoachRole || isOsRole ? isAdminRole && hasPrimaryCoverage : isAdminRole;
  });

  // If no admin with 'Primary' coverage was found, find one with 'Temporary' coverage
  if (!coveringAdmin) {
    coveringAdmin = workflow.coveringAdmins?.find(coveringAdmin => {
      const isAdminRole = roles.includes(coveringAdmin.role);
      const hasTemporaryCoverage = coveringAdmin.coverageTypes.includes('Temporary');
      return isCoachRole || isPtRole || isOsRole ? isAdminRole && hasTemporaryCoverage : isAdminRole;
    });
  }
  return coveringAdmin;
};
const roleMapping = {
  coach: {
    coverageType: 'Primary',
    displayName: 'HC'
  },
  physical_therapist: {
    coverageType: 'Clinical',
    displayName: 'PT'
  },
  super: {
    coverageType: 'Clinical',
    displayName: 'PT'
  },
  pt: {
    coverageType: 'Clinical',
    displayName: 'PT'
  }
};
export const getCoverageDetails = (careTeamData: CareTeamCoverageInfo[], loggedInAdminUuid: string, setActiveCareTeamMembers: React.Dispatch<React.SetStateAction<CareTeamCoverageInfo[]>>, setPrimaryAsigneeDetails: React.Dispatch<React.SetStateAction<{
  name: string;
  role: string;
  coverageType: string;
  isActive: boolean;
}>>): string | undefined => {
  const activeCareTeamMembers = careTeamData.filter(member => member.active);
  setActiveCareTeamMembers(activeCareTeamMembers);
  const loggedInUser = activeCareTeamMembers.find(member => member.adminUuid === loggedInAdminUuid);
  setPrimaryAssigneeFromActiveMembers(activeCareTeamMembers, setPrimaryAsigneeDetails);
  if (loggedInUser) {
    return generateCoverageMessage(loggedInUser, activeCareTeamMembers);
  }
  return undefined; // Explicitly return undefined if no loggedInUser is found
};
function setPrimaryAssigneeFromActiveMembers(activeCareTeamMembers: CareTeamCoverageInfo[], setPrimaryAsigneeDetails: React.Dispatch<React.SetStateAction<PrimaryAssigneeDetails>>): void {
  for (const member of activeCareTeamMembers) {
    if ([Roles.PhysicalTherapist, Roles.Super, Roles.PT].includes((member.adminRole as Roles)) && member.type === CoachCoverageType.Clinical) {
      setPrimaryAsigneeDetails({
        name: `${member.adminName} & team`,
        role: member.adminRole,
        coverageType: member.type,
        isActive: member.active
      });
      break; // Exit the loop after setting the first matching member
    }
  }
}
function generateCoverageMessage(user: CareTeamCoverageInfo, activeCareTeamMembers: CareTeamCoverageInfo[]): string | undefined {
  const roleInfo = roleMapping[(user.adminRole as keyof typeof roleMapping)];
  if (!roleInfo || user.type !== CoachCoverageType.Temporary) return;
  const primaryCoverage = activeCareTeamMembers.find(member => member.type === roleInfo.coverageType && member.adminRole === user.adminRole);
  return primaryCoverage ? `You are covering for ${roleInfo.displayName} ${primaryCoverage.adminName}.` : 'You are providing temporary coverage to the member';
}
const mapCreateWorkflowOptions = (options: MenuOptions[], openCreateWorkflowTab: (workflowType: CoachWorkflowTypes | PtWorkflowTypes) => void): HHDropdownItemProps[] => options.map(option => ({
  label: option.label,
  onClick: () => openCreateWorkflowTab((option.value as PtWorkflowTypes | CoachWorkflowTypes))
}));
export const getCreateCareCoordinationWorkflowsOptions = (roles: OktaRole[], openCreateWorkflowTab: (workflowType: CoachWorkflowTypes | PtWorkflowTypes) => void): HHDropdownItemProps[] => {
  if (roles?.includes(OktaRole.PhysicalTherapist)) {
    return mapCreateWorkflowOptions(PT_CREATE_WORKFLOW_OPTIONS, openCreateWorkflowTab);
  } else if (roles?.includes(OktaRole.Coach)) {
    return mapCreateWorkflowOptions(COACH_CREATE_WORKFLOW_OPTIONS, openCreateWorkflowTab);
  } else if (roles?.includes(OktaRole.OnboardingSpecialist)) {
    return mapCreateWorkflowOptions(OS_CREATE_WORKFLOW_OPTIONS, openCreateWorkflowTab);
  }
  return [];
};