import { NAV_SPLIT_TREATMENTS } from '../../../constants/strings/split';
export const SPLIT_TREATMENTS = {
  UNREAD_MESSAGE_ML: 'unread-message-ml',
  HEALTH_LOG_NOTES: 'member-health-log',
  REENGAGER_WORKFLOW_MIGRATION: 'reengager-workflow-migration',
  CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT: NAV_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT,
  PT_POOLED_DASHBOARD: 'pt-pooled-dashboard',
  PT_HLN_ML: 'pt-hln-ml',
  PT_UNREAD_MESSAGES_ML: 'pt-unread-message-ml',
  PT_ACTION_PLAN_REVIEW: 'pt-action-plan-review-workflow',
  SETUP_ACTION_PLAN_WORKFLOW: 'setup-action-plan-workflow',
  COACH_WEEKLY_GOAL_EXPIRY: 'coach-weekly-goal-expiry-workflow',
  COACH_MEMBER_UPDATED_WEEKLY_GOAL: 'coach-member-updated-weekly-goal-workflow',
  OS_MEMBER_ALERTS_REMINDERS: 'os-member-alerts',
  COACH_VIDEO_VISIT: 'coach-video-visit'
};
export const ON = 'on';
export const OFF = 'off';