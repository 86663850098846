import { useLazyQuery, useQuery } from '@apollo/client';
import { Alert, AlertStates, HHAlertPopover, HHBox, HHDate, HHGrid, HHTypography, PriorityStates, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { ctpTokens } from '@hinge-health/react-component-library/dist/styles/ctp-theme';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SESSION_ID } from '../../constants/strings/member';
import { ON } from '../../constants/strings/split';
import { MEMBER_ALERT_REMIND_AT } from '../../constants/strings/workflows-queue';
import { CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowTypes, QueueCategoryType, sidebarWorkflowsWithSubtitles, workflowsWithSubtitles, WorkflowTypes } from '../../custom-types';
import { useCurrentStoredAdminHook } from '../../hooks/use-current-stored-admin';
import { useGenericMixpanelTrack } from '../../hooks/use-generic-mixpanel';
import { useInterval } from '../../hooks/use-interval';
import useQueueQueryParams from '../../hooks/use-queue-query-params';
import { MIXPANEL_EVENTS } from '../../lib/mixpanel/mixpanel-event-names';
import { WorkflowSelectedFromDashboardProperties } from '../../lib/mixpanel/types/generic-mixpanel-event-types';
import { getPriorityString } from '../../modules/dashboard/utils/get-workflow-priority';
import { etModificationTransformText, getEnsoSubtitle, getOutreachNeededSubtitle, getSurgeryTrendSubtitle, getVideoVisitSubtitle, TRANSFORM_TEXT_LENGTH } from '../../modules/dashboard/utils/get-workflow-subtitle';
import { SPLIT_TREATMENTS } from '../../modules/member-360/components/communication/conversations/constants/splits';
import { SIMPLIFIED_WORKFLOWS } from '../../modules/member-360/constants/splits';
import { PREFERRED_NAME_UPDATED } from '../../modules/member-360/modules/member-alerts/constants/strings/member-alert-form';
import { GET_LAST_ET_SESSION, GET_USER_INFO } from '../../modules/member-360/queries/user.graphql';
import { transformIndicationsToText, transformText } from '../../modules/utils/transform-text';
import { coachWorkflowIdMapsByQueueCategoryAtom, currentQueueCategoryIndexAtom, currentQueueWorkflowIdAtom, osWorkflowIdMapsByQueueCategoryAtom, ptWorkflowIdMapsByQueueCategoryAtom, queueDisplay } from '../../state/queue-atoms';
import { useHealthLogForQueueLazyQuery, WorkflowPayload } from '../../types';
import { getDueState } from '../../utils/get-due-state';
import { getPatientDetails } from '../../utils/get-patient-name';
import { getLocalStorageItem } from '../../utils/local-storage-utils';
import { quoteString } from '../../utils/string-helpers';
import { buildWorkflowUrl, getByOktaRole } from '../utils';
const styles = {
  container: {
    paddingX: 8,
    paddingY: 3,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ctpTokens.palette.sand.shades['30p']
    }
  },
  rightColumn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  memberInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    mr: 1,
    fontSize: 16
  }
};
const LEVEL_TEXT = 'Level';
const getAlertStatus = (memberAlerts: Alert[], today: string): AlertStates => {
  const latestAlert = memberAlerts[memberAlerts.length - 1];
  const isUpcoming: boolean = latestAlert.startAt >= today;
  if (isUpcoming) {
    return AlertStates.Upcoming;
  } else if (latestAlert.paused && !isUpcoming) {
    return AlertStates.Paused;
  } else {
    return AlertStates.Active;
  }
};
const getActiveMemberAlerts = (workflow: WorkflowPayload, today: string): Alert[] => (workflow.memberAlerts?.filter(alert => alert.endAt >= today) as Alert[]) || [];
export const QueueWorkflowRow = ({
  workflow,
  type
}: {
  workflow: WorkflowPayload;
  type: QueueCategoryType;
}): JSX.Element => {
  const genericMixpanelEvents = useGenericMixpanelTrack();
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const [getHealthLog, {
    data
  }] = useHealthLogForQueueLazyQuery();
  const logUuid = workflow.customFields?.logUuid;
  const {
    workflowId
  } = useQueueQueryParams();
  const navigate = useNavigate();
  const setCurrentQueueCategoryIndex = useSetAtom(currentQueueCategoryIndexAtom);
  const [currentQueueWorkflowId, setCurrentQueueWorkflowId] = useAtom(currentQueueWorkflowIdAtom);
  const splitClient = useClient();
  const videoVisitDeprecationTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.CTT_DEPRECATION_VIDEO_VISIT, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON;
  const [today, setToday] = useState<string>(DateTime.now().toString());
  const [activeMemberAlerts, setActiveMemberAlerts] = useState<Alert[]>([]);
  const [coachWorkflowIdMaps] = useAtom(coachWorkflowIdMapsByQueueCategoryAtom);
  const [ptWorkflowIdMaps] = useAtom(ptWorkflowIdMapsByQueueCategoryAtom);
  const [osWorkflowIdMaps] = useAtom(osWorkflowIdMapsByQueueCategoryAtom);
  const setDisplayWorkflowQueue = useSetAtom(queueDisplay);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const [userInfoPrefetched, setUserInfoPrefetched] = useState(false);
  const [getUserInfo] = useLazyQuery(GET_USER_INFO);
  const {
    data: lastEtSessionData
  } = useQuery(GET_LAST_ET_SESSION, {
    variables: {
      uuid: workflow.patient?.id
    },
    skip: !workflow.patient?.id || workflow.type !== PtWorkflowTypes.FirstWeekEnsoCheckIn && workflow.type !== CoachWorkflowTypes.WeeklyEnsoReview
  });
  const isSimplifiedWorkflowsSplitOn = splitClient?.getTreatment(SIMPLIFIED_WORKFLOWS, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON;
  const workflowIdMaps = getByOktaRole(coachWorkflowIdMaps, osWorkflowIdMaps, ptWorkflowIdMaps, hingeHealthAuthState?.accessToken?.claims?.roles);
  const priorityString = (getPriorityString((workflow?.priority as number)) as string);
  useInterval(() => {
    setToday(DateTime.now().toString());
  }, 60000);
  useEffect(() => {
    if (logUuid) {
      getHealthLog({
        variables: {
          uuid: logUuid
        }
      });
    }
  }, [getHealthLog, logUuid]);
  useEffect(() => {
    setActiveMemberAlerts(getActiveMemberAlerts(workflow, today));
  }, [today, workflow]);
  const isCurrentWorkflow = workflow.id.toString() === workflowId;
  const dueState = type === QueueCategoryType.Encounter ? PriorityStates.Default : getDueState(workflow.isPastDue, workflow.latestTaskDate);
  const dueDate = type === QueueCategoryType.Encounter ? workflow.customFields?.callDate : workflow.latestTaskDate || '';
  const dateHasIcon = [PriorityStates.Overdue, PriorityStates.DueSoon].includes(dueState) || type === QueueCategoryType.Encounter;
  const currentIndications = (workflow.indications || []).map(({
    name,
    priority
  }) => ({
    name,
    isPrimary: priority === 0
  }));
  const getSubtitle = (): string => {
    const customFieldsBody = quoteString(workflow?.customFields?.body);
    switch ((workflow.type as WorkflowTypes)) {
      case CoachWorkflowTypes.UnreadMessages:
      case PtWorkflowTypes.UnreadMessages:
      case OsWorkflowTypes.UnreadMessages:
        return customFieldsBody;
      case CoachWorkflowTypes.MemberHealthLog:
      case CoachWorkflowTypes.MemberPainUptick:
      case PtWorkflowTypes.HealthLog:
      case PtWorkflowTypes.PainUptick:
        return quoteString(data?.healthLog.note);
      case PtWorkflowTypes.Acute:
      case PtWorkflowTypes.Chronic:
      case PtWorkflowTypes.FifteenMinute:
      case PtWorkflowTypes.Surgery:
      case PtWorkflowTypes.HouseCall:
      case CoachWorkflowTypes.CoachVideoVisit:
        return getVideoVisitSubtitle(workflow);
      case CoachWorkflowTypes.CoachEscalation:
      case PtWorkflowTypes.ClinicalEscalation:
        return quoteString(workflow.customFields?.description);
      case PtWorkflowTypes.EtModification:
        return etModificationTransformText(workflow).toString();
      case PtWorkflowTypes.MedicalEmergencyFollowUp:
        return `${LEVEL_TEXT} ${workflow?.customFields?.level}`;
      case PtWorkflowTypes.SurgeryTrend:
        return getSurgeryTrendSubtitle(workflow);
      case PtWorkflowTypes.FirstWeekEnsoCheckIn:
      case CoachWorkflowTypes.WeeklyEnsoReview:
        return getEnsoSubtitle(workflow, lastEtSessionData);
      case CoachWorkflowTypes.OutreachNeeded:
      case OsWorkflowTypes.OutreachNeeded:
        return getOutreachNeededSubtitle(workflow);
      case PtWorkflowTypes.ModularIamUnreadMessages:
        return quoteString(transformText(workflow?.customFields?.body, TRANSFORM_TEXT_LENGTH).toString());
      case PtWorkflowTypes.PtMemberAlertReminder:
      case CoachWorkflowTypes.MemberAlertReminder:
      case OsWorkflowTypes.MemberAlertReminder:
        return (transformText(workflow?.customFields?.memberAlert?.description, TRANSFORM_TEXT_LENGTH) as string);
      case PtWorkflowTypes.IndicationUpdate:
        return `${transformIndicationsToText(currentIndications)} from ${transformIndicationsToText(workflow?.customFields?.previousIndications)}`;
      default:
        return '';
    }
  };
  useEffect(() => {
    if (isCurrentWorkflow && workflow.id != currentQueueWorkflowId) {
      workflowIdMaps.every((workflowIdMap, index) => {
        const hasId = workflowIdMap.has(workflow.id);
        if (hasId) {
          setCurrentQueueCategoryIndex(index);
          setCurrentQueueWorkflowId(workflow.id);
          const sessionId = getLocalStorageItem(SESSION_ID) || null;
          genericMixpanelEvents<WorkflowSelectedFromDashboardProperties>(MIXPANEL_EVENTS.WORKFLOW_SELECTED_FROM_QUEUE, {
            selectedWorkflowID: workflow.id,
            selectedWorkflowName: workflow.type,
            selectedWorkflowState: workflow.state,
            selectedWorkflowCreatedAt: workflow.createdAt,
            selectedWorkflowMemberUuid: (workflow.patient?.uuid as string),
            selectedWorkflowPriority: priorityString,
            timeStamp: Date.now().toString(),
            sessionId
          });
        }
        return !hasId;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueueWorkflowId, isCurrentWorkflow, setCurrentQueueCategoryIndex, setCurrentQueueWorkflowId, workflow.id, workflowIdMaps, currentStoredAdmin?.uuid, workflow.type]);
  const [preferredName, setPreferredName] = useState('');
  useEffect(() => {
    const setUserPreferredName = (preferredName_0: string): void => {
      if (preferredName_0) {
        setPreferredName(preferredName_0);
      }
    };
    const handlePreferredNameUpdated = (event: Event): void => {
      const message = (event as CustomEvent).detail;
      const preferredName_1 = message['message'];
      const userId = message['id'];
      if (workflow.patient?.id === userId) {
        setUserPreferredName(preferredName_1);
      }
    };
    parent.window.addEventListener(PREFERRED_NAME_UPDATED, handlePreferredNameUpdated);
    return () => {
      parent.window.removeEventListener(PREFERRED_NAME_UPDATED, handlePreferredNameUpdated);
    };
  }, [workflow.patient?.id]);
  const handlePrefetchUserInfo = (): void => {
    if (workflow.patient?.id && !userInfoPrefetched) {
      getUserInfo({
        variables: {
          uuid: workflow.patient.id
        }
      }).then(() => {
        setUserInfoPrefetched(true);
      });
    }
  };
  return <HHGrid sx={styles.container} container columnSpacing={2.75} onClick={(): void => {
    navigate((buildWorkflowUrl({
      id: workflow.id,
      type: workflow.type,
      patientId: workflow.patient?.id || '',
      nextId: 0
    }, isSimplifiedWorkflowsSplitOn, videoVisitDeprecationTreatment) as string));
    setDisplayWorkflowQueue(false);
  }} onMouseOver={handlePrefetchUserInfo} data-testid={'workflow-row'}>
      <HHGrid item xs={9}>
        <HHBox sx={styles.memberInfo}>
          {isCurrentWorkflow && <PlayArrowIcon sx={styles.icon} htmlColor={ctpTokens.palette.green[60]} data-testid={`arrow-${workflow.id}`} />}
          <HHTypography hhVariant="body">
            {preferredName && workflow.patient ? `${preferredName} ${workflow.patient.lastName}` : getPatientDetails(workflow.patient)}
          </HHTypography>
          {!!activeMemberAlerts.length && <HHAlertPopover alerts={activeMemberAlerts} remindAtText={MEMBER_ALERT_REMIND_AT} status={getAlertStatus(activeMemberAlerts, today)} isSeeMore={false} />}
        </HHBox>
        {[...sidebarWorkflowsWithSubtitles, ...workflowsWithSubtitles].includes((workflow.type as CoachWorkflowTypes | PtWorkflowTypes)) && <HHTypography hhVariant="body-disabled" noWrap>
            {getSubtitle()}
          </HHTypography>}
      </HHGrid>
      <HHGrid sx={styles.rightColumn} item xs={3}>
        <HHDate dueDate={dueDate} withoutSuffix iconPosition={dateHasIcon ? 'right' : ''} dueState={dueState} iconColor={type === QueueCategoryType.Encounter ? ctpTokens.palette.navy[40] : undefined} />
      </HHGrid>
    </HHGrid>;
};