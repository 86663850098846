import { c as _c } from "react-compiler-runtime";
import { AlertStates, ctpTokens, HHAlertPopover, HHBox, HHTableCell, HHTypography } from '@hinge-health/react-component-library';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { COACH_WORKFLOWS_TABLE, MEMBER_ALERT_REMIND_AT } from '../../../../constants/strings/workflows-table';
import { Alert } from '../../../../custom-types';
import { useInterval } from '../../../../hooks/use-interval';
import { WorkflowMemberDataFragment } from '../../../../types';
import { createFullName } from '../../../../utils/get-patient-name';
import { styles as cellStyles } from './style';
const styles = {
  container: {
    display: 'flex'
  },
  memberInfo: {
    display: 'flex',
    flexDirection: 'column'
  }
};
interface WorkflowMemberCellProps {
  workflow: WorkflowMemberDataFragment;
}
const getAlertStatus = (memberAlerts: Alert[], today: string): AlertStates => {
  const latestAlert = memberAlerts[memberAlerts.length - 1];
  const isUpcoming: boolean = latestAlert.startAt >= today;
  if (isUpcoming) {
    return AlertStates.Upcoming;
  } else if (latestAlert.paused && !isUpcoming) {
    return AlertStates.Paused;
  } else {
    return AlertStates.Active;
  }
};
const getActiveMemberAlerts = (workflow: WorkflowMemberDataFragment, today: string): Alert[] => (workflow.memberAlerts?.filter(alert => alert.endAt >= today) as Alert[]) || [];
export const WorkflowMemberCell = t0 => {
  const $ = _c(23);
  const {
    workflow
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = DateTime.now().toString();
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const [today, setToday] = useState(t1);
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = [];
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const [activeMemberAlerts, setActiveMemberAlerts] = useState(t2);
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = () => {
      setToday(DateTime.now().toString());
    };
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  useInterval(t3, 60000);
  let t4;
  let t5;
  if ($[3] !== workflow || $[4] !== today) {
    t4 = () => {
      setActiveMemberAlerts(getActiveMemberAlerts(workflow, today));
    };
    t5 = [today, workflow];
    $[3] = workflow;
    $[4] = today;
    $[5] = t4;
    $[6] = t5;
  } else {
    t4 = $[5];
    t5 = $[6];
  }
  useEffect(t4, t5);
  const memberId = workflow.patient?.id;
  let t6;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = {
      display: "flex"
    };
    $[7] = t6;
  } else {
    t6 = $[7];
  }
  let t7;
  if ($[8] !== workflow.patient) {
    t7 = createFullName(workflow.patient);
    $[8] = workflow.patient;
    $[9] = t7;
  } else {
    t7 = $[9];
  }
  let t8;
  if ($[10] !== t7) {
    t8 = <HHTypography hhVariant="body" layoutStyles={t6}>{t7}</HHTypography>;
    $[10] = t7;
    $[11] = t8;
  } else {
    t8 = $[11];
  }
  const t9 = memberId ? memberId : COACH_WORKFLOWS_TABLE.SECTION_DETAILS.DEFAULT;
  let t10;
  if ($[12] !== t9) {
    t10 = <HHTypography hhVariant="variant-bypass" variant="body1" color={ctpTokens.palette.neutral[40]}>{t9}</HHTypography>;
    $[12] = t9;
    $[13] = t10;
  } else {
    t10 = $[13];
  }
  let t11;
  if ($[14] !== t8 || $[15] !== t10) {
    t11 = <HHBox sx={styles.memberInfo}>{t8}{t10}</HHBox>;
    $[14] = t8;
    $[15] = t10;
    $[16] = t11;
  } else {
    t11 = $[16];
  }
  let t12;
  if ($[17] !== activeMemberAlerts || $[18] !== today) {
    t12 = !!activeMemberAlerts.length && <HHAlertPopover alerts={activeMemberAlerts} remindAtText={MEMBER_ALERT_REMIND_AT} status={getAlertStatus(activeMemberAlerts, today)} isSeeMore={false} />;
    $[17] = activeMemberAlerts;
    $[18] = today;
    $[19] = t12;
  } else {
    t12 = $[19];
  }
  let t13;
  if ($[20] !== t11 || $[21] !== t12) {
    t13 = <HHTableCell sx={cellStyles.cell}><HHBox sx={styles.container}>{t11}{t12}</HHBox></HHTableCell>;
    $[20] = t11;
    $[21] = t12;
    $[22] = t13;
  } else {
    t13 = $[22];
  }
  return t13;
};