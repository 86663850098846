import { NAV_SPLIT_TREATMENTS } from '../../../../../../constants/strings/split';
export const SPLIT_TREATMENTS = {
  COMMUNICATION_PANEL_SMS: 'communication-panel-sms',
  IAM_SCHEDULED_MESSAGES: 'iam-scheduled-messages',
  CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT: NAV_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT,
  CTT_DEPRECATION_VIDEO_VISIT: 'ctt-deprecation-video-visit',
  DISABLE_PHI_MASKING: 'disable-pii-masking',
  ENABLE_OFFSHORE_ACCESS: 'enable_offshore_access_based_on_member_data',
  COACH_WEEKLY_GOAL_EXPIRY: 'coach-weekly-goal-expiry-workflow',
  CONVERSATIONS_IN_COMMS_PANEL: 'conversation-tab-in-comms-panel',
  MODULAR_IAM_PAIN_INSIGHTS: 'modular-iam-pain-insights',
  CONFIGURATOR_2_0_ENHANCEMENT: 'configurator-2-0-enhancement'
};